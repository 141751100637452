import React from 'react'
import { Box } from '@mui/material'
import { dayJsStrFixer } from '../../utils/dayJsHelper'

interface ChatMessageProps {
  messageType: string
  messageText: string
  messageCreatedAt: string
}

function ChatMessage({
  messageType,
  messageText,
  messageCreatedAt,
}: ChatMessageProps) {
  const bgColor = messageType === 'outgoing' ? 'rgb(9, 105, 218)' : 'lightgrey'
  const fontColor = messageType === 'outgoing' ? 'white' : 'black'
  const borderRadius =
    messageType === 'outgoing' ? '8px 8px 0 8px' : '8px 8px 8px 0'
  return (
    <>
      <div
        style={{
          backgroundColor: bgColor,
          padding: '.5rem',
          borderRadius: borderRadius,
          color: fontColor,
          display: 'inline-block',
        }}
      >
        <p style={{ margin: 0 }}>{messageText}</p>
      </div>
      <Box sx={{ fontSize: '10px', mt: '4px' }}>
        {dayJsStrFixer(messageCreatedAt.replaceAll('-', '/')).format('hh:mm A')}
      </Box>
    </>
  )
}

export default ChatMessage
