import React, { useState } from 'react'
import Modal from 'react-modal'
import { useMutation } from '@tanstack/react-query'
import { sendMatchCancellation } from '../../api'
import { ChallengeDetails } from '../ladder/Ladder.types'
import { Box, Button } from '@mui/material'

interface ChallengeCancellationModalProps {
  modalIsOpen: boolean
  closeModal: () => void
  challengeDetails: ChallengeDetails
}

interface CancellationMutationFnArgs {
  ladderId: number
  matchId: number | undefined
}

const ChallengeCancellationModal = ({
  modalIsOpen,
  closeModal,
  challengeDetails,
}: ChallengeCancellationModalProps) => {
  const [cancellationSent, setCancellationSent] = useState(false)

  const closeWrapperFn = () => {
    setCancellationSent(false)
    closeModal()
  }

  const mutation = useMutation({
    mutationFn: ({ ladderId, matchId }: CancellationMutationFnArgs) => {
      if (matchId) {
        setCancellationSent(true)
        return sendMatchCancellation(ladderId, matchId)
      }
      return Promise.reject()
    },
  })

  const renderChallengeDetail = () => {
    return cancellationSent && mutation.isSuccess ? (
      <h3>Your match with {challengeDetails.name} has been cancelled.</h3>
    ) : (
      <div>
        <h3>Cancel Match Invite</h3>
        <p>
          Are you sure you want to cancel your match with{' '}
          {challengeDetails.name}?
        </p>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'end',
            marginTop: 3,
          }}
        >
          <Button
            variant="text"
            onClick={closeWrapperFn}
            sx={{ marginRight: 1 }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              mutation.mutate({
                ladderId: challengeDetails.ladderId,
                matchId: challengeDetails.matchId,
              })
            }}
          >
            Yes
          </Button>
        </Box>
      </div>
    )
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      contentLabel="Challenge Cancellation Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={closeWrapperFn}
      style={{
        content: {
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          borderRadius: '8px',
        },
        overlay: {
          overflow: 'hidden',
        },
      }}
    >
      {renderChallengeDetail()}
    </Modal>
  )
}

export default ChallengeCancellationModal
