import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  useMediaQuery,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import './NavMenu.css'
import { getMatches } from '../../api'
import { useQuery } from '@tanstack/react-query'
import { useUserContext } from '../../UserContext'
import dayjs from 'dayjs'
import { useReportMatchModalContext } from '../../ReportMatchModalContext'
import { Person } from '@mui/icons-material'
import { dayJsFixer } from '../../utils/dayJsHelper'

const NavMenu = () => {
  const { setIsReportMatchModalOpen } = useReportMatchModalContext()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { user } = useUserContext()

  const navigate = useNavigate()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  const latestLadderForUser = user?.ladders?.at(-1)
  const ladderId = latestLadderForUser?.id || -1

  const open = Boolean(anchorEl)

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const showNavLinks = !!user

  const { data: matches } = useQuery({
    queryKey: ['matches'],
    queryFn: () => getMatches(ladderId),
    staleTime: 0,
    retry: false,
    enabled: showNavLinks,
  })

  const showRecordScoreMenuItem = () => {
    let show = false

    matches?.forEach((match) => {
      match.matchProposals.some((mp) => {
        if (
          mp.state === 'accepted' &&
          match.state === 'confirmed' &&
          mp.teeTime
        ) {
          show = dayJsFixer(mp.teeTime).diff(dayjs(), 'minutes') <= 0
          return true
        }
        return false
      })
    })

    return show
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, backgroundColor: 'white' }}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <Box component="div" sx={{ flexGrow: 1, alignItems: 'center' }}>
              <MenuItem sx={{ display: 'inline-flex' }}>
                <Link to="/ladder">
                  <img
                    src="rgc_logo_black.avif"
                    style={{ width: '170px' }}
                    alt="Random Golf Club logo"
                  />
                </Link>
              </MenuItem>
              {isDesktop && showNavLinks && (
                <>
                  <MenuItem
                    sx={{ display: 'inline-flex' }}
                    color="inherit"
                    onClick={() => navigate('/ladder')}
                  >
                    Ladder
                  </MenuItem>
                  <MenuItem
                    sx={{ display: 'inline-flex' }}
                    color="inherit"
                    onClick={() => navigate('/matches')}
                  >
                    Matches
                  </MenuItem>
                  <MenuItem
                    sx={{ display: 'inline-flex' }}
                    color="inherit"
                    onClick={() => navigate('/faq')}
                  >
                    FAQs
                  </MenuItem>
                  {showRecordScoreMenuItem() ? (
                    <MenuItem
                      sx={{ display: 'inline-flex' }}
                      color="inherit"
                      onClick={() => setIsReportMatchModalOpen(true)}
                    >
                      Record Score
                    </MenuItem>
                  ) : null}
                </>
              )}
            </Box>
            {isDesktop && showNavLinks && (
              <MenuItem color="inherit" onClick={() => navigate('/profile')}>
                <Person />
              </MenuItem>
            )}
            {!isDesktop && showNavLinks && (
              <>
                <IconButton
                  aria-label="expand menu"
                  aria-controls={open ? 'nav-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  size="small"
                  onClick={handleMenuClick}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="nav-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    color="inherit"
                    onClick={() => {
                      handleMenuClose()
                      navigate('/ladder')
                    }}
                  >
                    Ladder
                  </MenuItem>
                  <MenuItem
                    color="inherit"
                    onClick={() => {
                      handleMenuClose()
                      navigate('/matches')
                    }}
                  >
                    Matches
                  </MenuItem>
                  <MenuItem
                    color="inherit"
                    onClick={() => {
                      handleMenuClose()
                      navigate('/faq')
                    }}
                  >
                    FAQs
                  </MenuItem>
                  {showRecordScoreMenuItem() ? (
                    <MenuItem
                      color="inherit"
                      onClick={() => {
                        handleMenuClose()
                        setIsReportMatchModalOpen(true)
                      }}
                    >
                      Record Score
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    color="inherit"
                    onClick={() => {
                      handleMenuClose()
                      navigate('/profile')
                    }}
                  >
                    Profile
                  </MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  )
}

export default NavMenu
