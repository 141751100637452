import React from 'react'
import LadderDetails from './components/ladder/LadderDetails'
import LandingPage from './components/common/LandingPage'
import Profile from './components/profile/Profile'
import InvitationPage from './components/invitation/InvitationPage'
import MatchesPage from './components/matches/MatchPage'
import FaqPage from './components/common/FaqPage'
import { useUserContext } from './UserContext'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { Container } from '@mui/material'

const ProtectedRoute = () => {
  const { user, isLoading } = useUserContext()

  if (isLoading) return <></>

  if (!user && !isLoading) {
    return <Navigate to="/" replace />
  }

  return (
    <Container fixed>
      <Outlet />
    </Container>
  )
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/faq"
        element={
          <Container fixed>
            <FaqPage />
          </Container>
        }
      />
      <Route path="/invitation/:id" element={<InvitationPage />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/ladder" element={<LadderDetails />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/matches" element={<MatchesPage />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
