import React, { useEffect, useState } from 'react'
import { Box, Button, Card } from '@mui/material'

import './Login.css'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import { useUserContext } from '../../UserContext'
import { useNavigate } from 'react-router-dom'

function LandingPage() {
  const { user, isLoading } = useUserContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!!user) navigate('/ladder')
  }, [navigate, user])

  const [registerToggle, setRegisterToggle] = useState(false)

  if (isLoading) return <></>

  return (
    <Box id="landing-page-container">
      <Card id="landing-card">
        {registerToggle ? (
          <>
            <h3>Register</h3>
            <RegisterForm />
          </>
        ) : (
          <>
            <h3 style={{ textAlign: 'center' }}>Sign In</h3>
            <LoginForm />
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: '1rem',
          }}
        >
          <Button
            variant="text"
            onClick={() => setRegisterToggle(!registerToggle)}
            disableRipple
          >
            {registerToggle
              ? 'Already have an account? Sign in'
              : 'Click here to register'}
          </Button>
        </Box>
      </Card>
    </Box>
  )
}

export default LandingPage
