import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import { useQuery } from '@tanstack/react-query'

import { getInvitationDetail, sendAcceptedInvite, sendLogin } from '../../api'
import './InvitationPage.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserContext } from '../../UserContext'
import { AxiosError } from 'axios'

function InvitationPage() {
  const [password, setPassword] = useState('')
  const [homeCourse, setHomeCourse] = useState('')
  const [handicap, setHandicap] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [privacyPolicyAck, setPrivacyPolicyAck] = useState(false)
  const [notificationOptIn, setNotificationOptIn] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [requestsPending, setRequestsPending] = useState(false)
  const [registrationError, setRegistrationError] = useState<AxiosError | null>(
    null
  )
  const navigate = useNavigate()
  const { id } = useParams()
  const { setUserInContext } = useUserContext()

  const {
    isPending,
    isError,
    data: invitationDetail,
  } = useQuery({
    queryKey: ['invitationDetail', id],
    queryFn: () =>
      getInvitationDetail(id || '').catch((err) => {
        return Promise.reject(new Error(err.message))
      }),
    staleTime: 10000,
    retry: 0,
  })

  const ladderInvitation =
    invitationDetail?.ladderInvitations &&
    invitationDetail?.ladderInvitations.length > 0
      ? invitationDetail?.ladderInvitations[0]
      : null

  useEffect(() => {
    if ((ladderInvitation === null && !isPending) || isError) navigate('/')
  }, [ladderInvitation, isPending, isError, navigate])

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handleHomeCourseChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHomeCourse(event.target.value)
  }

  const handleHandicapChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 5) {
      return
    }
    setHandicap(event.target.value)
  }

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 11) {
      return
    }
    setPhone(event.target.value)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const formSubmitHandler = () => {
    setRequestsPending(true)
    sendAcceptedInvite({
      password,
      handicap: parseFloat(handicap),
      homeCourse,
      invitationToken: id || '',
      name,
      phone,
    })
      .then(() =>
        sendLogin({
          email: invitationDetail.email,
          password,
        })
      )
      .then((res) => {
        setUserInContext(res.data.data)
        navigate('/ladder')
      })
      .catch((error) => {
        setRegistrationError(error)
        setRequestsPending(false)
      })
  }

  if (isPending || isError) return <></>

  const isFormCompleted =
    !!name &&
    !!password &&
    !!homeCourse &&
    !!handicap &&
    !!phone &&
    privacyPolicyAck &&
    notificationOptIn

  return (
    <Box id="invitation-page-container">
      <Card id="invitation-registration-card">
        <h3>{`You've been invited to the ${ladderInvitation.ladder.name}!`}</h3>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': {
              my: '1rem',
            },
            '& .MuiFormControl-root': {
              my: '1rem',
            },
            '& .MuiButton-root': {
              my: '1rem',
            },
          }}
          autoComplete="off"
        >
          <TextField
            required
            fullWidth
            label="Name"
            id="name-input"
            value={name}
            onChange={handleNameChange}
            InputLabelProps={{ shrink: true }}
          ></TextField>
          <TextField
            disabled
            fullWidth
            label="Email"
            id="email-input"
            value={invitationDetail.email}
            InputLabelProps={{ shrink: true }}
          ></TextField>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel shrink={true} htmlFor="password-input">
              Password
            </InputLabel>
            <OutlinedInput
              id="password-input"
              value={password}
              label="Password"
              onChange={handlePasswordChange}
              type={showPassword ? 'text' : 'password'}
              notched={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <TextField
            required
            fullWidth
            label="Home Course"
            id="home-course-input"
            value={homeCourse}
            onChange={handleHomeCourseChange}
            InputLabelProps={{ shrink: true }}
          ></TextField>
          <TextField
            required
            fullWidth
            type="number"
            label="Handicap"
            id="handicap-input"
            value={handicap}
            onChange={handleHandicapChange}
            InputLabelProps={{ shrink: true }}
          ></TextField>
          <TextField
            required
            fullWidth
            label="Phone"
            id="phone-input"
            value={phone}
            onChange={handlePhoneChange}
            InputLabelProps={{ shrink: true }}
          ></TextField>
          <FormControl style={{ marginTop: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  aria-labelledby="privacy-policy-confirmation-label"
                  value={privacyPolicyAck}
                  name="radio-buttons-group"
                  onChange={() => setPrivacyPolicyAck(!privacyPolicyAck)}
                />
              }
              label={
                <p style={{ margin: 0 }}>
                  Yes, I agree to the{' '}
                  <a href="https://randomgolfclub.com/policies/privacy-policy">
                    RGC Ladder Terms and Privacy Policy
                  </a>
                </p>
              }
            />
          </FormControl>
          <FormControl style={{ marginTop: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  aria-labelledby="handicap-fairness-confirmation-label"
                  name="radio-buttons-group"
                />
              }
              label={
                <p style={{ margin: 0 }}>
                  I attest that the handicap I have provided here is either accurate to my existing GHIN index or is an honest estimate of what my handicap should be. I understand that failure to represent my handicap honestly may result in disqualification from The Ladder competition.
                </p>
              }
            />
          </FormControl>
          <FormControl style={{ marginTop: 0, marginBottom: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  aria-labelledby="privacy-policy-confirmation-label"
                  value={notificationOptIn}
                  name="radio-buttons-group"
                  onChange={() => setNotificationOptIn(!notificationOptIn)}
                />
              }
              label={
                <p style={{ margin: 0 }}>
                  Yes, I consent to notifications about Ladder activity via
                  email and SMS
                </p>
              }
            />
          </FormControl>
          <Button
            fullWidth
            disabled={!isFormCompleted || requestsPending}
            variant="contained"
            onClick={formSubmitHandler}
          >
            Register
          </Button>
          {registrationError ? (
            <Alert severity="error">
              There was an error during registration:
              <br />
              {registrationError.message} - {registrationError.code}
            </Alert>
          ) : null}
        </Box>
      </Card>
    </Box>
  )
}

export default InvitationPage
