import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
} from '@mui/material'
import './FaqPage.css'

const Faqs = [
  {
    title: 'What is the RGC Ladder?',
    body: (
      <>
        <p>
          The RGC Ladder is a community-driven, "King of the Hill" style
          competition that begins in February and runs through the end of May.
          RGC members challenge each other to individual net stroke play matches
          during the competition period and can track their movement up and down
          The Ladder as they win/lose matches.
        </p>
        <p>
          The top four RGC members at the close of the competition will meet at
          a special host venue for a one-day stroke play Ladder Finale that will
          be filmed by the RGC content team.
        </p>
      </>
    ),
  },
  {
    title: 'What is the format of individual Ladder Matches?',
    body: (
      <p>
        Individual Ladder matches are contested across either nine or eighteen
        holes of stroke play. The winning player in any given match is
        determined by the low net score.
      </p>
    ),
  },
  {
    title: 'What determines my position on the RGC Ladder?',
    body: (
      <>
        <p>
          The RGC Ladder assigns all players a rating based on their performance
          in the head-to-head matches against other Ladder participants. Upon
          entry into The Ladder, all players will begin with a default rating of
          1000.
        </p>
        <p>
          As players participate in Ladder matches, their ratings will fluctuate
          based upon the following factors:
        </p>
        <ul>
          <li>The final result of each match (e.g. win/loss/tie)</li>
          <li>The rating of their opponent</li>
          <li>The score differential between the players</li>
          <li>Length of the match (nine holes vs. eighteen holes)</li>
        </ul>
        <p>
          Accordingly, players will move up The Ladder fastest by winning
          matches by a wide margin against the highest-ranked players. Players
          will not move up The Ladder as quickly by playing against lower-ranked
          players and winning their matches by a narrow margin.
        </p>
        <p>
          Players can still move up/down The Ladder when their matches are tied
          depending on the rating of the two players. In case of a tie, the
          higher-ranked player will lose some ratings points while the
          lower-ranked player will gain some ratings points.
        </p>
        <p>
          Players can play nine hole matches for the purposes of Ladder
          competition, but those matches will be worth half of the value of an
          eighteen-hole match.
        </p>
      </>
    ),
  },
  {
    title: 'What prizes are being offered for performance in The Ladder?',
    body: (
      <>
        <p>
          The top four (4) RGC members at the close of the competition will meet
          at a special host venue for a one-day stroke play Ladder Finale that
          will be filmed by the RGC content team.
        </p>
        <p>
          The player who returns the lowest net score from The Ladder Finale
          will receive an RGC Champion's Box filled with all kinds of RGC
          merchandise including hats, shirts, headcovers, towels, shoes and golf
          balls.
        </p>
        <p>
          All other participants in The Ladder Finale will receive an RGC
          Mystery Box filled with additional RGC merchandise.
        </p>
      </>
    ),
  },
  {
    title: 'How do I challenge another player to a match?',
    body: (
      <>
        <p>
          Participants in The Ladder can find and challenge other participants
          by going to The Ladder home page. On the home page, you will see all
          other Ladder participants listed in order of their standing on The
          Ladder.
        </p>
        <b>See below:</b>
        <img
          src="assets/images/faq-send-challenge.png"
          alt="send challenge button location"
        />
      </>
    ),
  },
  {
    title: 'How do I connect with another player to schedule a match?',
    body: (
      <>
        <p>
          Once you've challenged another player to a match (or they've
          challenged you), navigate to your challenges on the "Matches" screen
          under the "Invites Sent" or "Invites Received" tabs. Once there, you
          can use our built-in chat feature that will allow you to reach out
          directly to your playing partner.
        </p>
        <p>
          Use this feature to coordinate schedules/venues on your upcoming
          match. Once you've agreed on the details (day of play, a time window
          and a venue), you're ready to move on to the confirmation process
          (described in the next question).
        </p>
        <b>See below:</b>
        <img
          src="assets/images/faq-send-message.png"
          alt="send message button location"
        />
      </>
    ),
  },
  {
    title:
      'Who is responsible for booking the tee time required to play a Ladder match?',
    body: (
      <p>
        Either player in a Ladder match can book the tee time, but make sure
        that you and your opponent discuss this as you talk about scheduling
        your upcoming match. No match should be confirmed until one of the two
        players has booked the necessary tee time.
      </p>
    ),
  },
  {
    title: 'How do I confirm a match?',
    body: (
      <>
        <p>
          Your match invites will be located either in the "Invites Sent" or
          "Invites Received" tab that can be found on the "Matches" page listed
          in the top navigation bar.
        </p>
        <p>
          Once you and your opponent have agreed on the details for your
          upcoming match (e.g. date, approximate time and the venue), the most
          important next step will be for you or your opponent to call the golf
          course and reserve the required tee time.{' '}
        </p>
        <p>
          As soon as you and your opponent completed these two key steps, you're
          ready to confirm your match!
        </p>
        <p>
          Find the line that lists your opponent's name and look at the buttons
          on the right side of the screen. Click the circle with the check mark
          in the middle of it (the button in the middle depicted below) and that
          should bring up the prompt to enter the details of your match.
        </p>
        <p>
          Enter the course name, the date, the time and the number of holes
          you'll be playing. Click the check box to confirm that you or your
          opponent has booked your tee time and you're ready to confirm your
          match!
        </p>
        <p>
          Once you've sent a match confirmation, your opponent will receive a
          message on his/her end that he/she must confirm the match details as
          well. As soon as your opponent has completed this step, the match is
          on and will move into the "Upcoming" match stage.
        </p>
        <b>See below:</b>
        <img
          src="assets/images/faq-confirm-match-details.png"
          alt="send match details button location"
        />
      </>
    ),
  },
  {
    title: 'How do I accept a match confirmation?',
    body: (
      <>
        <p>
          Your opponent may also send you a match confirmation once you both
          have agreed to the match details and one of you has booked the
          necessary tee time. Should your opponent send you a match confirmation
          first, a "CONFIRM DETAILS" button (depicted below) will appear on the
          right side of the invitation listed in the "Invites Sent" or "Invites
          Received" tab that can be found on the "Matches" page listed in the
          top navigation bar.
        </p>
        <p>
          Click that button and confirm the details for your match and you're
          all set to go play!
        </p>
        <b>See below:</b>
        <img
          src="assets/images/faq-confirm-details.png"
          alt="confirm match details button location"
        />
      </>
    ),
  },
  {
    title: 'How much time do I have to accept/decline a match invitation?',
    body: (
      <>
        <p>
          Match invitations will live on the "Matches" screen in either the
          "Invites Sent" or "Invites Received" tab for two weeks. After the
          two-week period has expired, the invitation will disappear from your
          list of invitations.
        </p>
        <p>
          <b>Important Note:</b> There is no penalty for failing to respond to a
          Ladder invitation and there is no penalty for sending an invitation
          that is not accepted.
        </p>
      </>
    ),
  },
  {
    title:
      'What happens if I fail to accept or cancel an existing match invitation?',
    body: (
      <>
        <p>
          There are no penalties for match cancellations or for failure to
          accept a match invitation. Should you pass on a match invitation or
          have to cancel an upcoming match, neither you nor your opponent will
          suffer any penalties as it relates to Ladder standings.
        </p>
        <p>
          You can cancel a match by navigating to its current status (either
          Sent, Received or Upcoming) and clicking the circle with the X in the
          middle as depicted below.
        </p>
        <p>
          Please be courteous when it comes to handling match cancellations.
          Your fellow members schedule their time around confirmed matches and
          often need to find babysitters for their kids, negotiate with their
          spouse for time away from home, take days off of work or otherwise
          jump through hoops to make their matches possible.
        </p>
        <p>
          We ask that you respect the time of your fellow members and don't take
          match cancellations lightly.
        </p>
        <b>See below:</b>
        <img
          src="assets/images/faq-cancel-match.png"
          alt="cancel match button location"
        />
      </>
    ),
  },
  {
    title:
      'What should I do if I need to cancel a match at the last minute or communicate quickly with my opponent in an upcoming match?',
    body: (
      <>
        <p>
          We understand that unexpected events come up that may cause you to be
          delayed in your arrival for a Ladder match or may require you to
          cancel a Ladder match at the last minute.
        </p>
        <p>
          If you need to communicate quickly with your opponent within 48 hours
          of your upcoming match, we have built out a one-time text message
          feature that will allow you to send a one-way text message to your
          opponent.
        </p>
        <p>
          You can utilize this feature by navigating to the "Matches" screen and
          going to the "Upcoming" match tab that will show the upcoming match
          you have with your opponent. You should see your upcoming match
          details listed along with the following set of icons:
        </p>
        <img
          src="assets/images/faq-text-message.png"
          alt="send text message button"
          style={{ maxWidth: '350px' }}
        />
        <p>
          Click the phone icon on the right hand side and that will bring up a
          prompt that allows you to send a one-time text message to your
          opponent.
        </p>
        <p>
          <b>Important Notes:</b> This is a one-way text feature that your
          opponent will not be able to respond to. Should you wish to engage in
          a back and forth text exchange with your opponent, we suggest that you
          text him/her your telephone number and you can continue the
          conversation via standard text messaging.
        </p>
        <p>
          It is also important to note that, for privacy reasons, your phone
          number (and the phone number of your opponent) will not appear on
          either end of the one-way text message.
        </p>
      </>
    ),
  },
  {
    title: 'When my match is completed, how do I report the results?',
    body: (
      <>
        <p>There are two ways to report match scores.</p>
        <p>
          The first way is to navigate to the "Matches" screen and scroll to the
          "Completed" matches tab. All of your completed matches will be listed
          here alongside their results, but matches that have been completed and
          do not have reported results will be denoted with the below
          exclamation point icon:
        </p>
        <img
          src="assets/images/faq-record-score-1.png"
          alt="one way to record a match score"
        />
        <p>
          Click on the exclamation point icon next to the name of your opponent
          and you will be prompted to report your net stroke play scores from
          your match.
        </p>
        <p>
          The second way to report match scores is through the banner at the top
          of The Ladder website:
        </p>
        <img
          src="assets/images/faq-record-score-2.png"
          alt="another way to record a match score"
        />
        <p>
          Click on the "Record Score" link at the top of the banner and a dialog
          box will appear that will prompt you to report your scores for any
          outstanding Ladder matches.
        </p>
        <p>
          Important Note: Players are to report their net medal play scores
          after the application of their course handicap for the day. Do not
          report gross scores without the handicap adjustment as this will
          compromise your Ladder standing.
        </p>
      </>
    ),
  },
  {
    title: 'What do I do if my opponent submits an incorrect score?',
    body: (
      <p>
        We understand that there will be occasional disputes about match
        scoring. Should there be a disagreement over the outcome of a Ladder
        match, please send us an email at{' '}
        <a href="mailto:rgcladder@randomgolfclub.com">
          rgcladder@randomgolfclub.com
        </a>{' '}
        with both the match details and any information about the dispute and we
        will make sure to find an amicable resolution.
      </p>
    ),
  },
  {
    title:
      'Are matches required to be played over eighteen holes or can I also play nine-hole matches?',
    body: (
      <p>
        Ladder matches can be played over both eighteen and nine holes at the
        option of the competing players. However, it is important to note that
        nine-hole matches will have half of the impact on both players’ standing
        in The Ladder as compared to eighteen hole matches.
      </p>
    ),
  },
  {
    title:
      'How many matches do I need to play in order to be competitive to win The Ladder?',
    body: (
      <>
        <p>
          There is no required minimum number of matches played in order to win
          The Ladder competition. Your standing on The Ladder will be determined
          by the quality of the competition you play against, your overall
          record against those competitors and the margin of victory/defeat in
          your matches.
        </p>
        <p>
          These factors create a strategic element in the challenge process
          during Ladder competition as your frequency of play and level of
          success in your matches will influence whom you decide to challenge.
          You will have to assess your standing and the type of competition you
          will need to challenge in order to advance up the Ladder.
        </p>
        <p>
          The more matches you can play and the more frequently you win, the
          more you can afford to take on lesser or equally-ranked players. The
          fewer matches you play and the fewer matches you win, the more you
          will have to challenge higher-ranked players.
        </p>
      </>
    ),
  },
  {
    title: 'Can I play matches with multiple players at the same time?',
    body: (
      <>
        <p>Absolutely!</p>
        <p>
          RGC members are not only allowed to play multiple Ladder matches at
          once, but are encouraged to do so. The Ladder can support an unlimited
          number of simultaneous matches between separate players as this is an
          excellent way for players to make quick progress in climbing to the
          top.
        </p>
      </>
    ),
  },
  {
    title: 'Where can I find the RGC/Ladder privacy policy?',
    body: (
      <p>
        Our privacy policy is available at{' '}
        <a href="https://randomgolfclub.com/policies/privacy-policy">
          https://randomgolfclub.com/policies/privacy-policy
        </a>
        .
      </p>
    ),
  },
]

function FaqPage() {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  const createAccordians = () => {
    return Faqs.map((faq, index) => {
      const panelId = `panel${index}`
      return (
        <Accordion
          expanded={expanded === panelId}
          onChange={handleChange(panelId)}
        >
          <AccordionSummary
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
            sx={{ backgroundColor: '#f7f7f7' }}
          >
            <p className="accordian-summary-text">{faq.title}</p>
          </AccordionSummary>
          <AccordionDetails>{faq.body}</AccordionDetails>
        </Accordion>
      )
    })
  }

  return (
    <Box sx={{ py: '16px' }}>
      <Card sx={{ p: '16px' }}>
        <h3>Frequently Asked Questions</h3>
        {createAccordians()}
      </Card>
    </Box>
  )
}

export default FaqPage
