import axios from 'axios'
import {
  AcceptedInvite,
  LoginRequest,
  MatchProposal,
  SendChallengeArguments,
  MatchResponse,
  RegistrationRequest,
} from './components/ladder/Ladder.types'
import applyCaseMiddleware from 'axios-case-converter'
import { AUTH_TOKEN_NAME } from './utils/constants'

const ladderApi = applyCaseMiddleware(
  axios.create({
    baseURL: '/',
    timeout: 10000,
  })
)

ladderApi.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'
    config.headers.Authorization = localStorage.getItem(AUTH_TOKEN_NAME)
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

ladderApi.interceptors.response.use(
  (res) => {
    const { authorization } = res.headers
    if (authorization) localStorage.setItem(AUTH_TOKEN_NAME, authorization)
    return res
  },
  (error) => Promise.reject(error)
)

export const getLadderDetail = () => {
  return ladderApi.get(`api/ladders`).then((res) => {
    return res.data
  })
}

export const getLoggedInUser = () => {
  return ladderApi.get('api/users/current')
}

export const getMatches = (id: number) => {
  return ladderApi
    .get(`api/ladders/${id}/matches`)
    .then((res): MatchResponse[] => res.data)
}

export const getMatchMessages = (ladderId: number, matchId: number) => {
  return ladderApi
    .get(`api/ladders/${ladderId}/matches/${matchId}/messages`)
    .then((res) => res.data)
}

export const sendMatchCancellation = (ladderId: number, matchId: number) => {
  return ladderApi.post(`api/ladders/${ladderId}/matches/${matchId}/cancel`)
}

export const sendMatchCompletion = (
  ladderId: number,
  matchId: number,
  challengerNetScore: string,
  challengeeNetScore: string
) => {
  return ladderApi.post(
    `api/ladders/${ladderId}/matches/${matchId}/record_score`,
    {
      challengerNetScore: challengerNetScore,
      challengeeNetScore: challengeeNetScore,
    }
  )
}

export const sendScoreConfirmation = (ladderId: number, matchId: number) => {
  return ladderApi.post(
    `api/ladders/${ladderId}/matches/${matchId}/confirm_score`
  )
}

export const sendMatchMessage = (
  ladderId: number,
  matchId: number,
  messageBody: string,
  matchProposals: MatchProposal[],
  notify: boolean = false
) => {
  return ladderApi.post(`api/ladders/${ladderId}/matches/${matchId}/messages`, {
    messageBody: messageBody,
    matchProposals: matchProposals,
    notify,
    smsNotification: notify,
  })
}

export const sendChallenge = ({
  ladderId,
  challenge,
}: SendChallengeArguments) => {
  return ladderApi
    .post(`api/ladders/${ladderId}/matches`, challenge)
    .then((res) => {
      return res.data
    })
}

export const sendMatchAcceptance = (
  ladderId: number,
  matchId: number,
  matchProposalId: number,
  matchProposal: MatchProposal
) => {
  return ladderApi.post(
    `api/ladders/${ladderId}/matches/${matchId}/accept_proposal`,
    {
      matchProposal: matchProposal,
      matchProposalId: matchProposalId,
    }
  )
}

export const getInvitationDetail = (id: string) => {
  return ladderApi.get(`api/invitations/${id}`).then((res) => {
    return res.data
  })
}

export const sendAcceptedInvite = (acceptedInvite: AcceptedInvite) => {
  return ladderApi.post('api/invitations/accept', acceptedInvite)
}

export const sendLogin = (loginReq: LoginRequest) => {
  return ladderApi.post('auth/sign_in', loginReq)
}

export const sendRegistration = (registrationRequest: RegistrationRequest) => {
  return ladderApi.post('api/leads', registrationRequest)
}
