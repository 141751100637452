import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Box,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableCell,
  TableRow,
  IconButton,
  TextField,
} from '@mui/material'
import { useUserContext } from '../../UserContext'
import { AUTH_TOKEN_NAME } from '../../utils/constants'
import { queryClient } from '../../AppQueryClient'
import { Check, Close, Create } from '@mui/icons-material'
import StyledTableRow from '../common/StyledTableRow'

function Profile() {
  const navigate = useNavigate()
  const { user, clearUserFromContext } = useUserContext()
  const [editingEmail, setEditingEmail] = useState(false)
  const userEmail = user?.email || ''
  const [localEmail, setLocalEmail] = useState(userEmail)

  const logout = () => {
    clearUserFromContext()
    localStorage.removeItem(AUTH_TOKEN_NAME)
    queryClient.clear()
    navigate('/')
  }

  const renderEmail = () => {
    return (
      <TableCell>
        {editingEmail ? (
          <>
            <TextField
              fullWidth
              label="Email"
              id="email-input"
              sx={{ marginBottom: '6px' }}
              value={localEmail}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setLocalEmail(evt.target.value)
              }
              InputLabelProps={{ shrink: true }}
            />
            <IconButton color="success">
              <Check
                onClick={() => {
                  alert(`Saving updated email adress: ${localEmail}`)
                  setEditingEmail(false)
                  setLocalEmail(userEmail)
                }}
              />
            </IconButton>
            <IconButton color="error">
              <Close
                onClick={() => {
                  setEditingEmail(false)
                  setLocalEmail(userEmail)
                }}
              />
            </IconButton>
          </>
        ) : (
          <>
            {localEmail || user?.email}{' '}
            <IconButton size="small" onClick={() => setEditingEmail(true)}>
              <Create />
            </IconButton>
          </>
        )}
      </TableCell>
    )
  }

  function renderContent() {
    return (
      <Box sx={{ py: '16px' }}>
        <Card sx={{ padding: { xs: '8px', sm: '16px' } }}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }}>
              <h3>{user?.name}</h3>
            </Box>
            <Box sx={{ alignItems: 'end' }}>
              <Button variant="outlined" onClick={logout}>
                Logout
              </Button>
            </Box>
          </Box>
          <Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: '6px' }}></TableCell>
                    <TableCell sx={{ padding: '6px' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <TableCell>Email</TableCell>
                    {renderEmail()}
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell>Home Course</TableCell>
                    <TableCell>{user?.homeCourse}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell>Handicap</TableCell>
                    <TableCell>{user?.handicap}</TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </Box>
    )
  }

  let contents = !user ? <></> : renderContent()

  return <div>{contents}</div>
}

export default Profile
