import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import Modal from 'react-modal'
import { getMatches, sendMatchCompletion } from '../../api'
import { useUserContext } from '../../UserContext'
import { MatchResponse } from '../ladder/Ladder.types'
import { useReportMatchModalContext } from '../../ReportMatchModalContext'

interface SendMatchCompletionArguments {
  ladderId: number
  matchId: number
  challengerNetScore: string
  challengeeNetScore: string
}

const ReportMatchModal = () => {
  const {
    isReportMatchModalOpen,
    setIsReportMatchModalOpen,
    selectedMatchId,
    setSelectedMatchId,
  } = useReportMatchModalContext()
  const [matchReported, setMatchReported] = useState(false)
  const closeWrapperFn = () => {
    setSelectedMatchId('')
    setChallengeeScore('')
    setChallengerScore('')
    setIsReportMatchModalOpen(false)
  }
  const [challengerScore, setChallengerScore] = useState('')
  const [challengeeScore, setChallengeeScore] = useState('')
  const { user } = useUserContext()

  const handleConfirmedMatchChange = (event: SelectChangeEvent) => {
    setSelectedMatchId(event.target.value as string)
  }

  const handleChallengeeScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChallengeeScore(event.target.value)
  }

  const handleChallengerScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChallengerScore(event.target.value)
  }

  const latestLadderForUser = user?.ladders?.at(-1)
  const ladderId = latestLadderForUser?.id || -1

  const { data: matches, isPending } = useQuery({
    queryKey: ['matches'],
    queryFn: () => getMatches(ladderId),
    staleTime: 0,
    retry: false,
    enabled: !!user,
  })

  const mutation = useMutation({
    mutationFn: ({
      ladderId,
      matchId,
      challengerNetScore,
      challengeeNetScore,
    }: SendMatchCompletionArguments) => {
      setMatchReported(true)
      setChallengeeScore('')
      setChallengerScore('')
      setSelectedMatchId('')
      return sendMatchCompletion(
        ladderId,
        matchId,
        challengerNetScore,
        challengeeNetScore
      )
    },
  })

  const getMatchOptions = (matches: MatchResponse[] | undefined) => {
    if (!matches) return []

    const options: [string, string][] = []

    matches.forEach((match) => {
      const isChallenger = match.challenger?.id === user?.id
      const opponentName = isChallenger
        ? match.challengee.name
        : match.challenger.name
      match.matchProposals.forEach((mp) => {
        if (mp.state === 'accepted' && match.state === 'confirmed') {
          options.push([`${opponentName} - ${mp.course}`, match.id.toString()])
        }
      })
    })

    return options
  }

  const selectedMatch = matches?.find((match) => {
    return match.id.toString() === selectedMatchId
  })

  const renderReportMatchDetail = () => {
    if (!matches?.find((match) => match.state === 'confirmed')) {
      return (
        <>
          <h3>Uh oh!</h3>
          <p>You don't have any confirmed matches to record a score for.</p>
        </>
      )
    }

    return matchReported ? (
      <>
        <h3>Thanks for sending your match result!</h3>
        <p>
          Thank you for confirming your match results! You can head over to the
          main Ladder page to view the updated standings. Time to play again!
        </p>
      </>
    ) : (
      <>
        <div>
          <h3>Report scores</h3>
          <p>
            We'll use your <b>net scores</b> to calculate points earned or lost.
            Check the ladder for your updated standing!
          </p>
          <FormControl fullWidth>
            <InputLabel id="confirmed-match-select-label">
              Select match
            </InputLabel>
            <Select
              labelId="confirmed-match-select-label"
              id="confirmed-match-select"
              value={selectedMatchId}
              label="Select match"
              onChange={handleConfirmedMatchChange}
            >
              {getMatchOptions(matches).map(([optText, matchId]) => (
                <MenuItem key={matchId} value={matchId}>
                  {optText}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedMatch ? (
            <>
              <TextField
                fullWidth
                type="number"
                label={`${selectedMatch?.challengee.name}'s net score`}
                id="winner-input"
                sx={{ mt: '1rem' }}
                value={challengeeScore}
                onChange={handleChallengeeScoreChange}
              ></TextField>
              <TextField
                fullWidth
                type="number"
                label={`${selectedMatch?.challenger.name}'s net score`}
                id="loser-input"
                sx={{ mt: '1rem' }}
                value={challengerScore}
                onChange={handleChallengerScoreChange}
              ></TextField>
            </>
          ) : null}
        </div>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'end',
            marginTop: 3,
          }}
        >
          <Button
            variant="text"
            onClick={closeWrapperFn}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!challengerScore || !challengeeScore}
            onClick={() => {
              const match = matches?.find(
                (match) => match.id.toString() === selectedMatchId
              )
              if (!match) return
              mutation.mutate({
                ladderId: ladderId,
                matchId: match.id,
                challengeeNetScore: challengeeScore,
                challengerNetScore: challengerScore,
              })
            }}
          >
            Confirm
          </Button>
        </Box>
      </>
    )
  }

  let contents = isPending ? (
    <p>
      <em>Loading...</em>
    </p>
  ) : (
    renderReportMatchDetail()
  )

  return (
    <Modal
      isOpen={isReportMatchModalOpen}
      onAfterOpen={() => {}}
      contentLabel="Report Match Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={closeWrapperFn}
      style={{
        content: {
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minHeight: 0,
          maxWidth: '400px',
          borderRadius: '8px',
        },
        overlay: {
          overflow: 'hidden',
        },
      }}
    >
      {contents}
    </Modal>
  )
}

export default ReportMatchModal
