import React, { ReactNode, createContext, useContext, useState } from 'react'

interface ReportMatchModalContextProps {
  children: ReactNode
}

export type ReportMatchModalContextType = {
  isReportMatchModalOpen: boolean
  setIsReportMatchModalOpen: (isOpen: boolean) => void
  selectedMatchId: string
  setSelectedMatchId: (matchId: string) => void
}

const ReportMatchModalContext = createContext<
  ReportMatchModalContextType | undefined
>(undefined)

export const ReportMatchModalProvider = ({
  children,
}: ReportMatchModalContextProps) => {
  const [isReportMatchModalOpen, setIsOpen] = useState(false)
  const [selectedMatchId, setMatchId] = useState('')

  const setIsReportMatchModalOpen = (isOpen: boolean) => setIsOpen(isOpen)
  const setSelectedMatchId = (matchId: string) => setMatchId(matchId)

  return (
    <ReportMatchModalContext.Provider
      value={{
        isReportMatchModalOpen,
        setIsReportMatchModalOpen,
        selectedMatchId,
        setSelectedMatchId,
      }}
    >
      {children}
    </ReportMatchModalContext.Provider>
  )
}

export const useReportMatchModalContext = () =>
  useContext(ReportMatchModalContext) as ReportMatchModalContextType
