import React from 'react'
import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface ChatWindowHeaderProps {
  recipientName: string
  closeHandler: () => void
}

function ChatWindowHeader({
  recipientName,
  closeHandler,
}: ChatWindowHeaderProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'black',
        px: '16px',
        borderRadius: '8px 8px 0 0',
      }}
    >
      <p
        style={{
          color: 'white',
          fontWeight: 600,
          textTransform: 'uppercase',
          letterSpacing: '2.16px',
        }}
      >
        {recipientName}
      </p>
      <IconButton
        sx={{
          display: 'flex-item',
          color: 'white',
          backgroundColor: 'transparent',
        }}
        onClick={closeHandler}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export default ChatWindowHeader
