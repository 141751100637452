import * as React from 'react'
import { Box, Button } from '@mui/material'
import CustomInput from '../common/CustomInput'

interface ChatInputProps {
  message: string
  messagingInputHandler: (arg: string) => void
  sendHandler: () => void
}

const ChatInput = ({
  message,
  messagingInputHandler,
  sendHandler,
}: ChatInputProps) => {
  return (
    <>
      <CustomInput
        aria-label="Chat input"
        multiline
        style={{
          marginTop: '16px',
        }}
        placeholder="Send a message to your opponent…"
        onChange={(evt) => messagingInputHandler(evt.target.value)}
        value={message}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'end',
          mt: '8px',
        }}
      >
        <Button variant="outlined" onClick={sendHandler}>
          Send
        </Button>
      </Box>
    </>
  )
}

export default ChatInput
