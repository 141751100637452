import React, { useEffect, useState } from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import MatchHistory from './MatchHistory'
import { useQuery } from '@tanstack/react-query'
import { getMatches } from '../../api'
import { useUserContext } from '../../UserContext'
import { MatchResponse } from '../ladder/Ladder.types'
import { dayJsFixer } from '../../utils/dayJsHelper'
import dayjs from 'dayjs'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const matchStateOrder = [
  'completed',
  'pending_score_confirmation',
  'confirmed',
  'unconfirmed',
  'canceled',
]

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function MatchesPage() {
  const [tabValue, setTabValue] = useState(0)
  const [matchesToDisplay, setMatchesToDisplay] = useState<
    MatchResponse[] | undefined
  >(undefined)
  const { user } = useUserContext()
  const latestLadderForUser = user?.ladders?.at(-1)
  const ladderId = latestLadderForUser?.id || -1

  const { data: matches, refetch } = useQuery({
    queryKey: ['matches'],
    queryFn: () => getMatches(ladderId),
    staleTime: 0,
    retry: false,
  })

  useEffect(() => {
    let filteredMatches: MatchResponse[] | undefined = []

    switch (tabValue) {
      case 0: //invitesSent
        filteredMatches = matches?.filter((m) => {
          return m.challenger.id === user?.id && m.state === 'unconfirmed'
        })
        break
      case 1: //invites received
        filteredMatches = matches?.filter((m) => {
          return m.challengee.id === user?.id && m.state === 'unconfirmed'
        })
        break
      case 2: // upcoming
        filteredMatches = matches?.filter((m) => {
          return (
            m.state === 'confirmed' &&
            m.matchProposals &&
            m.matchProposals.some(
              (mp) =>
                mp.state === 'accepted' &&
                dayJsFixer(mp.teeTime).diff(dayjs()) > 0
            )
          )
        })
        break
      case 3: // completed
        filteredMatches = matches?.filter((m) => {
          return (
            m.state === 'completed' ||
            (m.matchProposals &&
              m.matchProposals.some(
                (mp) =>
                  mp.state === 'accepted' &&
                  dayjs().diff(dayJsFixer(mp.teeTime)) > 0
              ))
          )
        })
        break
      case 4: // canceled
        filteredMatches = matches?.filter((m) => {
          return m.state === 'canceled'
        })
        break
      default:
        filteredMatches = matches
    }

    const sortedFilteredMatches = filteredMatches?.sort(
      (a, b) =>
        matchStateOrder.indexOf(a.state) - matchStateOrder.indexOf(b.state)
    )
    setMatchesToDisplay(sortedFilteredMatches)
  }, [matches, tabValue, user])

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Box sx={{ py: '16px' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          allowScrollButtonsMobile
          aria-label="basic tabs example"
        >
          <Tab label="Invites Sent" {...a11yProps(0)} />
          <Tab label="Invites Received" {...a11yProps(1)} />
          <Tab label="Upcoming" {...a11yProps(2)} />
          <Tab label="Completed" {...a11yProps(3)} />
          <Tab label="Canceled" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <MatchHistory
          matches={matchesToDisplay}
          types={['unconfirmed']}
          headerText={'Invites Sent'}
          refetchMatchData={refetch}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <MatchHistory
          matches={matchesToDisplay}
          types={['unconfirmed']}
          headerText={'Invites Received'}
          refetchMatchData={refetch}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <MatchHistory
          matches={matchesToDisplay}
          types={['active', 'confirmed']}
          headerText={'Upcoming Matches'}
          refetchMatchData={refetch}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <MatchHistory
          matches={matchesToDisplay}
          types={['completed', 'pending_score_confirmation']}
          headerText={'Completed Matches'}
          refetchMatchData={refetch}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={4}>
        <MatchHistory
          matches={matchesToDisplay}
          types={['canceled']}
          headerText={'Canceled Matches'}
          refetchMatchData={refetch}
        />
      </CustomTabPanel>
    </Box>
  )
}

export default MatchesPage
