import React, { ReactNode } from 'react'
import NavMenu from './NavMenu'
import { Footer } from './Footer'
import { ReportMatchModalProvider } from '../../ReportMatchModalContext'
import ReportMatchModal from '../modals/ReportMatchModal'

interface LayoutProps {
  children: ReactNode
}

function Layout({ children }: LayoutProps) {
  return (
    <div>
      <ReportMatchModalProvider>
        <NavMenu />
        <div style={{ paddingBottom: '64px' }}>{children}</div>
        <ReportMatchModal />
      </ReportMatchModalProvider>
      <Footer />
    </div>
  )
}

export default Layout
