import React, { useState } from 'react'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useUserContext } from '../../UserContext'
import { getMatchMessages } from '../../api'
import StyledTableRow from '../common/StyledTableRow'
import ChatWindow from '../chat/ChatWindow'
import {
  ChatBubbleOutline,
  CheckCircleOutline,
  ErrorOutline,
  HighlightOff,
  PhoneIphone,
  ReportOutlined,
} from '@mui/icons-material'
import ChallengeConfirmationModal from '../modals/ChallengeConfirmationModal'
import ChallengeCancellationModal from '../modals/ChallengeCancellationModal'
import { MatchResponse, Message } from '../ladder/Ladder.types'
import ChallengeAcceptanceModal from '../modals/ChallengeAcceptanceModal'
import dayjs from 'dayjs'
import DisputeResultModal from '../modals/DisputeResultModal'
import { useReportMatchModalContext } from '../../ReportMatchModalContext'
import { dayJsFixer } from '../../utils/dayJsHelper'
import SendTextModal from '../modals/SendTextModal'

interface MatchHistoryProps {
  matches: MatchResponse[] | undefined
  types: string[]
  headerText: string
  refetchMatchData: () => void
}

function MatchHistory({
  matches,
  types,
  headerText,
  refetchMatchData,
}: MatchHistoryProps) {
  const [chatOpen, setChatOpen] = useState(false)
  const [chatMessages, setChatMessages] = useState<Message[]>([])
  const [selectedLadderId, setSelectedLadderId] = useState<number>(-1)
  const [selectedMatchId, setSelectedMatchId] = useState<number>(-1)
  const [selectedMatchChallengee, setSelectedMatchChallengee] =
    useState<string>('')
  const [selectedMatchChallengeeId, setSelectedMatchChallengeeId] =
    useState<number>(-1)
  const [challengeModalOpen, setChallengeModalOpen] = useState(false)
  const [acceptanceModalOpen, setAcceptanceModalOpen] = useState(false)
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [disputeResultModalOpen, setDisputeResultModalOpen] = useState(false)
  const [sendTextModalOpen, setSendTextModalOpen] = useState(false)
  const { user } = useUserContext()
  const {
    setIsReportMatchModalOpen,
    setSelectedMatchId: setSelectedMatchIdReportMatchContext,
  } = useReportMatchModalContext()
  const theme = useTheme()
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClick = (matchId: number, callback: () => void) => {
    const match = matches?.find((m) => m.id === matchId)
    if (match) {
      const opponent =
        match.challenger?.id === user?.id ? match.challengee : match.challenger
      setSelectedLadderId(match.ladder.id)
      setSelectedMatchId(match.id)
      setSelectedMatchChallengee(opponent.name)
      setSelectedMatchChallengeeId(opponent.id)
      callback()
    }
  }

  const handleChatClick = (matchId: number) => {
    const match = matches?.find((m) => m.id === matchId)
    if (match) {
      getMatchMessages(match.ladder.id, match.id).then((chats) => {
        setChatMessages(chats || [])
        setChatOpen(true)
        document.body.style.overflow = 'hidden'
      })
    }
  }

  const sentProposal = (match: MatchResponse) => {
    const matchProposalId = match.matchProposals.at(-1)?.id

    if (matchProposalId) {
      const message = match.messages.find((msg) =>
        msg.matchProposals.find((mp) => mp.id === matchProposalId)
      )
      return message?.author.id === user?.id
    }

    return false
  }

  const getResultText = (isChallenger: boolean, match: MatchResponse) => {
    if (match.state !== 'completed') {
      return 'N/A'
    }

    const scoreText = isChallenger
      ? `${match.challengerNetScore ?? ''} - ${match.challengeeNetScore ?? ''}`
      : `${match.challengeeNetScore ?? ''} - ${match.challengerNetScore ?? ''}`

    const winnerLoserIndicator = match.winner?.id === user?.id ? 'W' : 'L'

    return `${match.winner?.id ? winnerLoserIndicator : ''} ${scoreText}`
  }

  const getDetailsText = (match: MatchResponse) => {
    const matchProposal = match.matchProposals.find(
      (mp) => mp.state === 'accepted'
    )

    if (
      matchProposal &&
      (match.state === 'confirmed' ||
        match.state === 'completed' ||
        match.state === 'pending_score_confirmation')
    ) {
      const matchTypeText =
        matchProposal.matchType === '9_holes' ? '9 Holes' : '18 Holes'
      const matchTeeTimeText = dayJsFixer(matchProposal.teeTime).format(
        'MM/DD/YYYY hh:mm A'
      )
      return isSmScreen ? (
        <>
          {matchProposal.course}
          <br />
          {matchTypeText}
          <br />
          {matchTeeTimeText}
        </>
      ) : (
        `${matchProposal.course} - ${matchTypeText} - ${matchTeeTimeText}`
      )
    }

    return ''
  }

  const isConfirmMatchDisabled = (matchId: number) => {
    const match = matches?.find((m) => m.id === matchId)
    if (match) {
      return match.state !== 'unconfirmed'
    }

    return false
  }

  const isCancelMatchDisabled = (matchId: number) => {
    const match = matches?.find((m) => m.id === matchId)
    if (match) {
      return (
        match.state === 'canceled' ||
        match.state === 'pending_score_confirmation' ||
        match.state === 'completed'
      )
    }

    return false
  }

  const isTextButtonDisabled = (matchId: number) => {
    const match = matches?.find((m) => m.id === matchId)

    return match && !match.smsEnabled
  }

  const renderReportScoreIcon = (matchId: number) => {
    const match = matches?.find((m) => m.id === matchId)
    const latestProposal = match?.matchProposals.at(-1)
    if (match?.state === 'confirmed' && latestProposal?.state === 'accepted') {
      return dayJsFixer(latestProposal.teeTime).diff(dayjs()) <= 0
    }

    return false
  }

  const renderActionButtons = (match: MatchResponse) => {
    return (
      <>
        {match.state === 'unconfirmed' &&
          match.matchProposals.at(-1) &&
          !sentProposal(match) && (
            <>
              <Button
                variant="outlined"
                onClick={() =>
                  handleClick(match.id, () => setAcceptanceModalOpen(true))
                }
              >
                Confirm details
              </Button>
              <br />
            </>
          )}
        <Tooltip
          title={<p style={{ margin: 0 }}>Send a message</p>}
          placement={isSmScreen ? 'top' : 'bottom'}
        >
          <span>
            <IconButton
              size="large"
              onClick={() =>
                handleClick(match.id, () => handleChatClick(match.id))
              }
            >
              <ChatBubbleOutline />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={<p style={{ margin: 0 }}>Send match details</p>}
          enterTouchDelay={isConfirmMatchDisabled(match.id) ? 0 : 700}
          placement={isSmScreen ? 'top' : 'bottom'}
        >
          <span>
            <IconButton
              disabled={isConfirmMatchDisabled(match.id)}
              size="large"
              sx={{ mr: '4px' }}
              onClick={() =>
                handleClick(match.id, () => setChallengeModalOpen(true))
              }
            >
              <CheckCircleOutline />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={<p style={{ margin: 0 }}>Cancel match</p>}
          enterTouchDelay={isCancelMatchDisabled(match.id) ? 0 : 700}
          placement={isSmScreen ? 'top' : 'bottom'}
        >
          <span>
            <IconButton
              disabled={isCancelMatchDisabled(match.id)}
              size="large"
              onClick={() =>
                handleClick(match.id, () => setCancellationModalOpen(true))
              }
            >
              <HighlightOff />
            </IconButton>
          </span>
        </Tooltip>
        {types.indexOf('confirmed') > -1 && (
          <Tooltip
            title={<p style={{ margin: 0 }}>Text opponent</p>}
            enterTouchDelay={isTextButtonDisabled(match.id) ? 0 : 700}
            placement={isSmScreen ? 'top' : 'bottom'}
          >
            <span>
              <IconButton
                disabled={isTextButtonDisabled(match.id)}
                size="large"
                onClick={() =>
                  handleClick(match.id, () => setSendTextModalOpen(true))
                }
              >
                <PhoneIphone />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {types.indexOf('completed') > -1 && (
          <Tooltip
            title={<p style={{ margin: 0 }}>Dispute result</p>}
            placement={isSmScreen ? 'top' : 'bottom'}
          >
            <span>
              <IconButton
                size="large"
                onClick={() =>
                  handleClick(match.id, () => setDisputeResultModalOpen(true))
                }
              >
                <ReportOutlined />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </>
    )
  }

  const isInvite = types.length === 1 && types.indexOf('unconfirmed') === 0
  const isUpcoming =
    types.length === 2 &&
    types.indexOf('active') > -1 &&
    types.indexOf('confirmed') > -1

  const getInviteMatchStatus = (match: MatchResponse) => {
    if (match.messages.length === 1) {
      return 'Awaiting response'
    }

    if (
      match.matchProposals.some((mp) => mp.state?.toLowerCase() === 'proposed')
    ) {
      return 'Awaiting confirmation'
    }

    return 'Finalizing details'
  }

  return (
    <>
      <h3>{headerText}</h3>
      {!matches || matches?.length === 0 ? (
        <p>Nothing to see here, yet!</p>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {isSmScreen ? 'Details' : 'Opponent'}
                  </TableCell>
                  {!isUpcoming && (
                    <TableCell
                      align="center"
                      sx={{
                        display: { xs: 'none', sm: 'none', md: 'table-cell' },
                      }}
                    >
                      {isInvite ? 'Status' : 'Result'}
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    sx={{ width: { xs: '5rem', sm: '15rem' } }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matches.map((match) => {
                  const isChallenger = match.challenger?.id === user?.id
                  return (
                    <StyledTableRow key={match.id}>
                      <TableCell align="left">
                        <span>
                          <b style={{ fontSize: '16px' }}>
                            {isChallenger
                              ? match.challengee.name
                              : match.challenger.name}
                          </b>
                          {renderReportScoreIcon(match.id) && (
                            <Tooltip
                              style={{
                                marginLeft: '8px',
                              }}
                              title={
                                <p style={{ margin: 0 }}>Record match scores</p>
                              }
                              enterTouchDelay={0}
                            >
                              <span>
                                <IconButton
                                  onClick={() => {
                                    setSelectedMatchIdReportMatchContext(
                                      match.id.toString()
                                    )
                                    setIsReportMatchModalOpen(true)
                                  }}
                                >
                                  <ErrorOutline />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        </span>
                        <br />
                        <Typography
                          variant="body2"
                          sx={{ letterSpacing: '2.16px' }}
                        >
                          {getDetailsText(match)}
                          {isSmScreen && !isInvite && !isUpcoming && (
                            <>
                              <br />
                              <b>Result:</b>{' '}
                              {getResultText(isChallenger, match)}
                            </>
                          )}
                          {isSmScreen && isInvite && !isUpcoming && (
                            <>
                              <br />
                              <b>Status:</b> {getInviteMatchStatus(match)}
                            </>
                          )}
                        </Typography>
                      </TableCell>
                      {!isUpcoming && (
                        <TableCell
                          align="center"
                          sx={{
                            display: {
                              xs: 'none',
                              sm: 'none',
                              md: 'table-cell',
                            },
                          }}
                        >
                          {isInvite
                            ? getInviteMatchStatus(match)
                            : getResultText(isChallenger, match)}
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        sx={{ width: { xs: '5rem', sm: '15rem' } }}
                      >
                        {renderActionButtons(match)}
                      </TableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {chatOpen ? (
        <ChatWindow
          initialMessages={chatMessages}
          ladderId={selectedLadderId}
          matchId={selectedMatchId}
          recipientName={selectedMatchChallengee}
          closeHandler={() => {
            setChatOpen(false)
            document.body.style.overflow = 'scroll'
          }}
        />
      ) : null}
      <SendTextModal
        ladderId={selectedLadderId}
        matchId={selectedMatchId}
        modalIsOpen={sendTextModalOpen}
        closeModal={() => setSendTextModalOpen(false)}
      />
      <ChallengeConfirmationModal
        challengeDetails={{
          challengeeId: selectedMatchChallengeeId,
          name: selectedMatchChallengee,
          ladderId: selectedLadderId,
          matchId: selectedMatchId,
        }}
        modalIsOpen={challengeModalOpen}
        closeModal={() => {
          refetchMatchData()
          setChallengeModalOpen(false)
        }}
      />
      <ChallengeAcceptanceModal
        challengeDetails={{
          ladderId: selectedLadderId,
          match: matches?.find((match) => match.id === selectedMatchId),
        }}
        modalIsOpen={acceptanceModalOpen}
        closeModal={() => {
          refetchMatchData()
          setAcceptanceModalOpen(false)
        }}
      />
      <ChallengeCancellationModal
        challengeDetails={{
          challengeeId: selectedMatchChallengeeId,
          name: selectedMatchChallengee,
          matchId: selectedMatchId,
          ladderId: selectedLadderId,
        }}
        modalIsOpen={cancellationModalOpen}
        closeModal={() => {
          refetchMatchData()
          setCancellationModalOpen(false)
        }}
      />
      <DisputeResultModal
        modalIsOpen={disputeResultModalOpen}
        closeModal={() => {
          setDisputeResultModalOpen(false)
        }}
      />
    </>
  )
}

export default MatchHistory
