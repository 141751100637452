import { Paper, Box, Backdrop } from '@mui/material'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useUserContext } from '../../UserContext'
import { sendMatchMessage } from '../../api'
import ChatTimeline from './ChatTimeline'
import ChatWindowHeader from './ChatWindowHeader'
import ChatInput from './ChatInput'
import { Message } from '../ladder/Ladder.types'

interface ChatWindowProps {
  initialMessages: Message[]
  ladderId: number | null
  matchId: number | undefined
  recipientName: string
  closeHandler: () => void
}

function ChatWindow({
  initialMessages,
  ladderId,
  matchId,
  recipientName,
  closeHandler,
}: ChatWindowProps) {
  const { user } = useUserContext()
  const [messages, setMessages] = useState<Message[]>(initialMessages)
  const [message, setMessage] = useState('')

  if (!user) return <></>

  const sendHandler = () => {
    messages.push({
      id: -1,
      body: message,
      author: user,
      createdAt: dayjs().format('YYYY-MM-DD hh:mm:ss A'),
      matchProposals: [],
    })
    if (ladderId && matchId) sendMatchMessage(ladderId, matchId, message, [])
    setMessage('')
  }

  const onClose = () => {
    setMessages([])
    closeHandler()
  }

  return (
    <>
      <Backdrop open={true} onClick={onClose}></Backdrop>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 64,
          right: 0,
          height: {
            xs: '80%',
            sm: '510px',
          },
          width: {
            sm: '400px',
            xs: '100%',
          },
          pb: '8px',
          borderRadius: '8px',
        }}
      >
        <ChatWindowHeader
          recipientName={recipientName}
          closeHandler={onClose}
        />
        <Box
          sx={{
            height: {
              xs: 'calc(80% - 72px)',
              sm: '310px',
            },
            overflow: 'scroll',
            my: '8px',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        >
          <ChatTimeline messages={messages} userId={user?.id} />
        </Box>
        <Box sx={{ px: '16px' }}>
          <ChatInput
            message={message}
            messagingInputHandler={setMessage}
            sendHandler={sendHandler}
          />
        </Box>
      </Paper>
    </>
  )
}

export default ChatWindow
