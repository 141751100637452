import React from 'react'

interface BannerProps {
  imageSrc: string
}

const Banner = ({ imageSrc }: BannerProps) => {
  return (
    <img
      src={imageSrc}
      style={{ width: '100%', maxHeight: '250px' }}
      decoding="async"
      loading="lazy"
      alt="TODO: needs to be passed in"
    />
  )
}

export default Banner
