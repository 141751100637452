import React, { useState } from 'react'
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { US_STATES } from '../../utils/constants'
import { sendRegistration } from '../../api'

function RegisterForm() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [metroArea, setMetroArea] = useState('')
  const [isUserRegistered, setIsUserRegistered] = useState(false)

  const handleStateChange = (event: SelectChangeEvent) => {
    setSelectedState(event.target.value)
  }

  const handleMetroAreaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMetroArea(event.target.value)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const formSubmitHandler = () => {
    // Update state to show message
    sendRegistration({
      name,
      email,
      metroArea: `${selectedState} - ${metroArea}`,
    }).then((res) => {
      setIsUserRegistered(true)
    })
  }

  return isUserRegistered ? (
    <Box>
      <p>
        You're registered! Thanks for being a part of the community. We'll send
        updates along the way!
      </p>
    </Box>
  ) : (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': {
          my: '1rem',
        },
        '& .MuiFormControl-root': {
          my: '1rem',
        },
        '& .MuiButton-root': {
          my: '1rem',
        },
      }}
      autoComplete="off"
    >
      <TextField
        fullWidth
        label="Name"
        id="name-input"
        value={name}
        onChange={handleNameChange}
        InputLabelProps={{ shrink: true }}
      ></TextField>
      <TextField
        fullWidth
        label="Email"
        id="email-input"
        value={email}
        onChange={handleEmailChange}
        InputLabelProps={{ shrink: true }}
      ></TextField>
      <FormControl fullWidth>
        <InputLabel id="state-label" shrink={true}>
          State
        </InputLabel>
        <Select
          labelId="state-label"
          id="confirmed-match-select"
          value={selectedState}
          label="State"
          onChange={handleStateChange}
          notched={true}
        >
          {US_STATES.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Closest Metro Area"
        id="metro-area-input"
        value={metroArea}
        onChange={handleMetroAreaChange}
        InputLabelProps={{ shrink: true }}
      ></TextField>
      <Button
        fullWidth
        disabled={!metroArea || !selectedState || !email}
        variant="contained"
        onClick={formSubmitHandler}
      >
        Register
      </Button>
    </Box>
  )
}

export default RegisterForm
