import dayjs from 'dayjs'

export const dayJsFixer = (
  day: dayjs.Dayjs | null | undefined
): dayjs.Dayjs => {
  // This is a "polyfill" for Safari. For some reason, DayJs cannot parse dates with hyphens in
  // Safari. This works fine in all other browsers, but not willing to sub in a new lib or dig
  // further into the root cause as of now.
  if (!day) return dayjs()

  return dayjs(day.toString().replaceAll('-', '/'))
}

export const dayJsStrFixer = (day: string): dayjs.Dayjs => {
  // This is a "polyfill" for Safari. For some reason, DayJs cannot parse dates with hyphens in
  // Safari. This works fine in all other browsers, but not willing to sub in a new lib or dig
  // further into the root cause as of now.

  return dayjs(day.replaceAll('-', '/'))
}
