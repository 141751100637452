import React, { useState } from 'react'
import Modal from 'react-modal'
import { useMutation } from '@tanstack/react-query'
import { sendMatchAcceptance } from '../../api'
import { MatchResponse } from '../ladder/Ladder.types'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useUserContext } from '../../UserContext'
import StyledTableRow from '../common/StyledTableRow'
import { dayJsFixer } from '../../utils/dayJsHelper'

interface ChallengeAcceptanceDetails {
  ladderId: number
  match: MatchResponse | undefined
}

interface ChallengeAcceptanceModalProps {
  modalIsOpen: boolean
  closeModal: () => void
  challengeDetails: ChallengeAcceptanceDetails
}

interface AcceptanceMutationFnArgs {
  ladderId: number
  match: MatchResponse | undefined
}

const ChallengeAcceptanceModal = ({
  modalIsOpen,
  closeModal,
  challengeDetails,
}: ChallengeAcceptanceModalProps) => {
  const [acceptanceSent, setAcceptanceSent] = useState(false)
  const { user } = useUserContext()

  const closeWrapperFn = () => {
    setAcceptanceSent(false)
    closeModal()
  }

  const mutation = useMutation({
    mutationFn: ({ ladderId, match }: AcceptanceMutationFnArgs) => {
      if (match && match.id) {
        const matchProposal = match.matchProposals?.at(-1)
        if (matchProposal && matchProposal.id) {
          setAcceptanceSent(true)
          return sendMatchAcceptance(
            ladderId,
            match.id,
            matchProposal.id,
            matchProposal
          )
        }
      }
      return Promise.reject()
    },
  })

  const isChallenger = challengeDetails.match?.challenger?.id === user?.id
  const opponent = isChallenger
    ? challengeDetails.match?.challengee.name
    : challengeDetails.match?.challenger.name
  const course = challengeDetails.match?.matchProposals.at(-1)?.course
  const matchTeeTime = dayJsFixer(
    challengeDetails.match?.matchProposals.at(-1)?.teeTime
  )

  const renderMatchDetails = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: '6px' }}></TableCell>
              <TableCell sx={{ padding: '6px' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <TableCell>Opponent</TableCell>
              <TableCell>{opponent}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell>Course</TableCell>
              <TableCell>{course}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell>Date</TableCell>
              <TableCell>{matchTeeTime.format('MM/DD/YYYY')}</TableCell>
            </StyledTableRow>
            <StyledTableRow>
              <TableCell>Tee Time</TableCell>
              <TableCell>{matchTeeTime.format('hh:mm A')}</TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const renderChallengeDetail = () => {
    return acceptanceSent && mutation.isSuccess ? (
      <div>
        <h3>Your match has been confirmed!</h3>
        {renderMatchDetails()}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'end',
            marginTop: 3,
          }}
        >
          <Button variant="text" onClick={closeWrapperFn}>
            Close
          </Button>
        </Box>
      </div>
    ) : (
      <div>
        <h3>Confirm match details</h3>
        {renderMatchDetails()}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'end',
            marginTop: 3,
          }}
        >
          <Button
            variant="text"
            onClick={closeWrapperFn}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              mutation.mutate({
                ladderId: challengeDetails.ladderId,
                match: challengeDetails.match,
              })
            }}
          >
            Confirm
          </Button>
        </Box>
      </div>
    )
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      contentLabel="Challenge Acceptance Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={closeWrapperFn}
      style={{
        content: {
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          borderRadius: '8px',
        },
        overlay: {
          overflow: 'hidden',
        },
      }}
    >
      {renderChallengeDetail()}
    </Modal>
  )
}

export default ChallengeAcceptanceModal
