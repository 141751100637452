import { Box, Typography } from '@mui/material'
import React from 'react'
import './Footer.css'
import dayjs from 'dayjs'

export const Footer = () => {
  const year = dayjs().format('YYYY')
  return (
    <footer className="footer">
      <hr
        style={{
          border: '.5px solid #bfbfbf',
          marginTop: '0',
          marginBottom: '16px',
        }}
      />
      <Box style={{ display: 'flex', flexDirection: 'row', margin: '0 16px' }}>
        <div className="signage">
          <Typography className="text-muted">{`© ${year} Random Golf Club`}</Typography>
        </div>
        <ul className="links">
          <li>
            <a
              className="text-muted"
              href="https://www.instagram.com/randomgolfclub/?hl=en"
              target="_blank"
              rel="noreferrer"
              aria-label="Follow on Instagram"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                width="24"
                className="icon icon-instagram"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 2.4c-2.607 0-2.934.011-3.958.058-1.022.046-1.72.209-2.33.446a4.705 4.705 0 0 0-1.7 1.107 4.706 4.706 0 0 0-1.108 1.7c-.237.611-.4 1.31-.446 2.331C2.41 9.066 2.4 9.392 2.4 12c0 2.607.011 2.934.058 3.958.046 1.022.209 1.72.446 2.33a4.706 4.706 0 0 0 1.107 1.7c.534.535 1.07.863 1.7 1.108.611.237 1.309.4 2.33.446 1.025.047 1.352.058 3.959.058s2.934-.011 3.958-.058c1.022-.046 1.72-.209 2.33-.446a4.706 4.706 0 0 0 1.7-1.107 4.706 4.706 0 0 0 1.108-1.7c.237-.611.4-1.31.446-2.33.047-1.025.058-1.352.058-3.959s-.011-2.934-.058-3.958c-.047-1.022-.209-1.72-.446-2.33a4.706 4.706 0 0 0-1.107-1.7 4.705 4.705 0 0 0-1.7-1.108c-.611-.237-1.31-.4-2.331-.446C14.934 2.41 14.608 2.4 12 2.4Zm0 1.73c2.563 0 2.867.01 3.88.056.935.042 1.443.199 1.782.33.448.174.768.382 1.104.718.336.336.544.656.718 1.104.131.338.287.847.33 1.783.046 1.012.056 1.316.056 3.879 0 2.563-.01 2.867-.056 3.88-.043.935-.199 1.444-.33 1.782a2.974 2.974 0 0 1-.719 1.104 2.974 2.974 0 0 1-1.103.718c-.339.131-.847.288-1.783.33-1.012.046-1.316.056-3.88.056-2.563 0-2.866-.01-3.878-.056-.936-.042-1.445-.199-1.783-.33a2.974 2.974 0 0 1-1.104-.718 2.974 2.974 0 0 1-.718-1.104c-.131-.338-.288-.847-.33-1.783-.047-1.012-.056-1.316-.056-3.879 0-2.563.01-2.867.056-3.88.042-.935.199-1.443.33-1.782.174-.448.382-.768.718-1.104a2.974 2.974 0 0 1 1.104-.718c.338-.131.847-.288 1.783-.33C9.133 4.14 9.437 4.13 12 4.13Zm0 11.07a3.2 3.2 0 1 1 0-6.4 3.2 3.2 0 0 1 0 6.4Zm0-8.13a4.93 4.93 0 1 0 0 9.86 4.93 4.93 0 0 0 0-9.86Zm6.276-.194a1.152 1.152 0 1 1-2.304 0 1.152 1.152 0 0 1 2.304 0Z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </li>
          <li>
            <a
              className="text-muted"
              href="https://www.youtube.com/channel/UCR4Yfr8HAZJd9X24dwuAt1Q"
              target="_blank"
              rel="noreferrer"
              aria-label="Follow on YouTube"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                width="24"
                className="icon icon-youtube"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.44 5.243c.929.244 1.66.963 1.909 1.876.451 1.654.451 5.106.451 5.106s0 3.452-.451 5.106a2.681 2.681 0 0 1-1.91 1.876c-1.684.443-8.439.443-8.439.443s-6.754 0-8.439-.443a2.682 2.682 0 0 1-1.91-1.876c-.45-1.654-.45-5.106-.45-5.106s0-3.452.45-5.106a2.681 2.681 0 0 1 1.91-1.876c1.685-.443 8.44-.443 8.44-.443s6.754 0 8.438.443Zm-5.004 6.982L9.792 15.36V9.091l5.646 3.134Z"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </li>
          <li>
            <a
              className="text-muted"
              href="https://www.tiktok.com/@randomgolfclubofficial"
              target="_blank"
              rel="noreferrer"
              aria-label="Follow on TikTok"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                width="24"
                className="icon icon-tiktok"
                viewBox="0 0 24 24"
              >
                <path
                  d="M20.027 10.168a5.125 5.125 0 0 1-4.76-2.294v7.893a5.833 5.833 0 1 1-5.834-5.834c.122 0 .241.011.361.019v2.874c-.12-.014-.237-.036-.36-.036a2.977 2.977 0 0 0 0 5.954c1.644 0 3.096-1.295 3.096-2.94L12.56 2.4h2.75a5.122 5.122 0 0 0 4.72 4.573v3.195"
                  fill="currentColor"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </Box>
    </footer>
  )
}
