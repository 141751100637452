import React from 'react'
import Modal from 'react-modal'

interface DisputeResultModalProps {
  modalIsOpen: boolean
  closeModal: () => void
}

const DisputeResultModal = ({
  modalIsOpen,
  closeModal,
}: DisputeResultModalProps) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      contentLabel="Challenge Cancellation Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={closeModal}
      style={{
        content: {
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          borderRadius: '8px',
        },
        overlay: {
          overflow: 'hidden',
        },
      }}
    >
      <div>
        <h3>Dispute match result</h3>
        <p>
          To dispute a match result, please send an email with the match details
          and the nature of the dispute to <b>rgcladder@randomgolfclub.com</b>.
        </p>
      </div>
    </Modal>
  )
}

export default DisputeResultModal
