import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getLadderDetail } from '../../api'
import { useUserContext } from '../../UserContext'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import GolfCourseIcon from '@mui/icons-material/GolfCourse'
import { ChallengeDetails, LadderDetail, Player } from './Ladder.types'
import {
  Box,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Banner from '../common/Banner'
import StyledTableRow from '../common/StyledTableRow'
import ChallengeInviteModal from '../modals/ChallengeInviteModal'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'

const defaultChallengeDetailsState = { challengeeId: 0, name: '', ladderId: 0 }
const trendStyle = {
  fontSize: '0.875rem',
  display: 'inline',
  textWrap: 'nowrap',
}
const trendIconStyle = { position: 'relative', top: '4px' }

function LadderDetails() {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [challengeDetails, setChallengeDetails] = useState<ChallengeDetails>(
    defaultChallengeDetailsState
  )
  const theme = useTheme()
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { user } = useUserContext()

  const {
    isPending,
    data: ladderDetail,
    error,
    refetch,
  } = useQuery({
    queryKey: ['ladderDetail'],
    queryFn: () => getLadderDetail(),
    staleTime: 300000, // 5 mins
    retry: 0,
  })

  const handleChallengeClick = (
    challengeeId: number,
    name: string,
    ladderId: number
  ) => {
    setChallengeDetails({
      challengeeId,
      name,
      ladderId,
    })
    setIsOpen(true)
  }

  const closeModal = () => {
    refetch()
    setChallengeDetails(defaultChallengeDetailsState)
    setIsOpen(false)
  }

  const getCurrentRankValue = (player: Player) => {
    const trend = player.currentRating - player.previousRating
    const noTrend = player.currentRating === player.previousRating

    return (
      <>
        <b>{player.currentRating}</b>{' '}
        {noTrend ? null : trend > 0 ? (
          <p style={trendStyle}>
            {'('}
            <ArrowUpward color="success" fontSize="small" sx={trendIconStyle} />
            {` +${trend} )`}
          </p>
        ) : (
          <p style={trendStyle}>
            {'('}
            <ArrowDownward color="error" fontSize="small" sx={trendIconStyle} />
            {` ${trend} )`}
          </p>
        )}
      </>
    )
  }

  const getPlayerCellValue = (player: Player) => {
    if (isXsScreen) {
      return (
        <>
          <b>{player.name}</b>
          <br />
          {getCurrentRankValue(player)}
        </>
      )
    }
    return player.name
  }

  const renderLadderRow = (
    userPlayer: Player | undefined,
    player: Player,
    index: number,
    isInactive: boolean
  ) => {
    return (
      <StyledTableRow key={player.id}>
        <TableCell align="left">{isInactive ? '-' : index + 1}</TableCell>
        <TableCell align="left">{getPlayerCellValue(player)}</TableCell>
        <TableCell
          align="center"
          sx={{ display: { xs: 'none', md: 'table-cell' } }}
        >
          {getCurrentRankValue(player)}
        </TableCell>
        <TableCell
          align="center"
          sx={{ display: { xs: 'none', md: 'table-cell' } }}
        >
          {player.handicap}
        </TableCell>
        <TableCell
          align="center"
          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
        >
          {player.wins}
        </TableCell>
        <TableCell
          align="center"
          sx={{ display: { xs: 'none', md: 'table-cell' } }}
        >
          {player.losses}
        </TableCell>
        <TableCell align="center">
          {userPlayer && player.id !== userPlayer.id ? (
            <Tooltip
              title={
                <p style={{ margin: 0 }}>
                  {player.challengable
                    ? 'Send challenge'
                    : 'Challenge already sent'}
                </p>
              }
              enterTouchDelay={0}
            >
              <span>
                <IconButton
                  onClick={() =>
                    handleChallengeClick(
                      player.id,
                      player.name,
                      ladderDetail.id
                    )
                  }
                  disabled={!player.challengable}
                >
                  <GolfCourseIcon
                    sx={{
                      fill: player.challengable ? '#000' : 'rgba(0,0,0,0.3)',
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <></>
          )}
        </TableCell>
      </StyledTableRow>
    )
  }

  const renderLadderDetail = (ladderDetail: LadderDetail | undefined) => {
    if (!ladderDetail) return

    const userPlayer = ladderDetail.players.find((p) => p.name === user?.name)

    ladderDetail.players.sort(
      (a, b) => parseFloat(a.handicap) - parseFloat(b.handicap)
    )

    const inactivePlayers = ladderDetail.players.filter(
      (player) => player.wins === 0 && player.losses === 0 && player.ties === 0
    )
    const activePlayers = ladderDetail.players
      .filter(
        (player) =>
          player.wins !== 0 || player.losses !== 0 || player.ties !== 0
      )
      .sort((a, b) => b.currentRating - a.currentRating)

    return (
      <div>
        <Banner imageSrc="https://s3.amazonaws.com/bucketeer-a563f9ac-0004-442d-adb4-b9b550a2d587/public/Ladder.Austin.Banner.Large.jpg" />
        <Box sx={{ py: '16px' }}>
          <div
            dangerouslySetInnerHTML={{ __html: ladderDetail.description }}
          ></div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">Player</TableCell>
                  <TableCell
                    align="center"
                    sx={{ display: { xs: 'none', md: 'table-cell' } }}
                  >
                    Rating
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ display: { xs: 'none', md: 'table-cell' } }}
                  >
                    Handicap
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                  >
                    Wins
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ display: { xs: 'none', md: 'table-cell' } }}
                  >
                    Losses
                  </TableCell>
                  <TableCell align="center">Send</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activePlayers.map((ap, index) =>
                  renderLadderRow(userPlayer, ap, index, false)
                )}
                {inactivePlayers.map((ap, index) =>
                  renderLadderRow(userPlayer, ap, index, true)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <ChallengeInviteModal
          modalIsOpen={modalIsOpen}
          // onAfterOpen={() => {}}
          closeModal={closeModal}
          challengeDetails={{
            challengeeId: challengeDetails.challengeeId,
            name: challengeDetails.name,
            ladderId: ladderDetail.id,
          }}
        />
      </div>
    )
  }

  if (error || !user) {
    return <></>
  }

  let contents = isPending ? (
    <></>
  ) : (
    renderLadderDetail(ladderDetail.find(Boolean))
  )

  return <div>{contents}</div>
}

export default LadderDetails
