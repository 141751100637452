import React, { useState } from 'react'
import Modal from 'react-modal'
import { useMutation } from '@tanstack/react-query'
import { sendMatchMessage } from '../../api'
import {
  ChallengeDetails,
  SendMatchConfirmationArguments,
} from '../ladder/Ladder.types'
import { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { dayJsFixer } from '../../utils/dayJsHelper'

interface ChallengeConfirmationModalProps {
  modalIsOpen: boolean
  closeModal: () => void
  challengeDetails: ChallengeDetails
}

const ChallengeConfirmationModal = ({
  modalIsOpen,
  closeModal,
  challengeDetails,
}: ChallengeConfirmationModalProps) => {
  const [challengeSent, setChallengeSent] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState('')
  const [matchType, setMatchType] = useState('')
  const [matchDate, setMatchDate] = React.useState<Dayjs | null>(null)
  const [teeTimeConfirmation, setTeeTimeConfirmation] = React.useState(false)

  const closeWrapperFn = () => {
    setChallengeSent(false)
    setSelectedCourse('')
    setMatchType('')
    setMatchDate(null)
    setTeeTimeConfirmation(false)
    closeModal()
  }

  const handleCourseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCourse(event.target.value as string)
  }

  const handleMatchTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMatchType((event.target as HTMLInputElement).value)
  }

  const mutation = useMutation({
    mutationFn: ({
      ladderId,
      matchId,
      matchProposal,
    }: SendMatchConfirmationArguments) => {
      const result = sendMatchMessage(
        ladderId,
        matchId,
        `Proposal sent for ${
          matchProposal.matchType === 'nine_hole' ? '9' : '18'
        } holes at ${matchProposal.course} on ${dayJsFixer(
          matchProposal.teeTime
        ).format('MM/DD/YYYY')}.`,
        [matchProposal]
      ).then((res) => {
        const matchProposalId = res.data?.matchProposals?.at(-1)?.id
        if (matchProposalId) {
          setMatchDate(null)
          setMatchType('')
          setSelectedCourse('')
        }
      })
      setChallengeSent(true)

      return result
    },
  })

  const isFormCompleted =
    !!selectedCourse && !!matchDate && !!matchType && !!teeTimeConfirmation

  const renderChallengeDetail = () => {
    return challengeSent && mutation.isSuccess ? (
      <div>
        <h3 style={{ textAlign: 'center' }}>Match confirmed!</h3>
        <p>
          Time to head to the course and play! Don't forget to have the winner
          of the match report the scores afterwards.
        </p>
      </div>
    ) : (
      <div>
        <h3>{`Confirm Match Details with ${challengeDetails.name}`}</h3>
        <FormControl fullWidth sx={{ mt: '1rem' }}>
          <TextField
            // labelId="course-select-label"
            required
            id="course-select"
            value={selectedCourse}
            label="Choose a course to play"
            onChange={handleCourseChange}
          ></TextField>
        </FormControl>
        <FormControl fullWidth sx={{ mt: '1rem' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              sx={{ width: '100%' }}
              label="Enter date of match"
              value={matchDate}
              onChange={(newMatchDate) => setMatchDate(newMatchDate)}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ mt: '.5rem' }}>
          <FormLabel hidden id="hole-selection-buttons-group-label">
            Number of holes to play
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="hole-selection-buttons-group-label"
            defaultValue="18"
            name="radio-buttons-group"
            onChange={handleMatchTypeChange}
          >
            <FormControlLabel
              value="eighteen_hole"
              control={<Radio />}
              label={<Typography variant="formLabel">18 Holes</Typography>}
            />
            <FormControlLabel
              value="nine_hole"
              control={<Radio />}
              label={<Typography variant="formLabel">9 Holes</Typography>}
            />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                aria-labelledby="tee-time-confirmation-label"
                value={teeTimeConfirmation}
                name="radio-buttons-group"
                onChange={() => setTeeTimeConfirmation(!teeTimeConfirmation)}
              />
            }
            label={
              <Typography variant="formLabel">
                I confirm that the tee time has been reserved at the above
                course.
              </Typography>
            }
          />
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'end',
            marginTop: 3,
          }}
        >
          <Button
            variant="text"
            onClick={closeWrapperFn}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!isFormCompleted}
            onClick={() => {
              mutation.mutate({
                ladderId: challengeDetails.ladderId,
                matchId: challengeDetails.matchId || -1,
                matchProposal: {
                  course: selectedCourse,
                  teeTime: matchDate,
                  matchType: matchType,
                },
              })
            }}
          >
            Confirm
          </Button>
        </Box>
      </div>
    )
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      contentLabel="Challenge Confirmation Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={closeWrapperFn}
      style={{
        content: {
          position: 'relative',
          top: '38%', // needed to give space to datetimepicker
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          borderRadius: '8px',
        },
        overlay: {
          overflow: 'hidden',
        },
      }}
    >
      {renderChallengeDetail()}
    </Modal>
  )
}

export default ChallengeConfirmationModal
