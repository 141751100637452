import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { queryClient } from './AppQueryClient'
import AppRoutes from './AppRoutes'
import Layout from './components/common/Layout'
import { UserContextProvider } from './UserContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/700.css'

import './custom.css'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    formLabel: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    formLabel?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    formLabel: true
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(0, 0, 0, 0.75)',
    },
  },
  typography: {
    formLabel: {
      textTransform: 'uppercase',
      letterSpacing: '2.16px',
      fontSize: '12px',
      fontWeight: 600,
    },
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          background: '#1c3d2a',
          '& .MuiTableCell-head': {
            color: 'white',
            fontWeight: 600,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          letterSpacing: '2.16px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          letterSpacing: '2.16px',
          fontSize: '12px',
          fontWeight: 600,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Inter", "Roboto", "sans-serif"',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: '"Inter", "Roboto", "sans-serif"',
          textTransform: 'uppercase',
          letterSpacing: '2.16px',
        },
      },
    },
  },
})

const App = () => {
  return (
    <GoogleOAuthProvider clientId="279621002237-jb329f9enms791ummjkl735aq3c9tn4r.apps.googleusercontent.com">
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <ThemeProvider theme={theme}>
            <Layout>
              <AppRoutes />
            </Layout>
          </ThemeProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  )
}

export default App
