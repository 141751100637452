import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { User } from './components/ladder/Ladder.types'
import { getLoggedInUser } from './api'

interface UserContextProps {
  children: ReactNode
}

export type UserContextType = {
  user: User | null
  isLoading: boolean
  setUserInContext: (user: User) => void
  clearUserFromContext: () => void
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserContextProvider = ({ children }: UserContextProps) => {
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchUser = async () => {
      const loggedInUser = await getLoggedInUser()
        .then((res) => {
          return res.data
        })
        .catch((_) => {
          return null
        })
        .finally(() => {
          setIsLoading(false)
        })

      if (loggedInUser) {
        setUser(loggedInUser)
      }
    }

    if (!user) {
      setIsLoading(true)
      fetchUser()
    } else {
      setIsLoading(false)
    }
  }, [user, setUser])

  const clearUserFromContext = () => setUser(null)

  const setUserInContext = (user: User) => setUser(user)

  return (
    <UserContext.Provider
      value={{ user, isLoading, setUserInContext, clearUserFromContext }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => useContext(UserContext) as UserContextType
