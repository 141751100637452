import React, { useState } from 'react'
import Modal from 'react-modal'
import { useMutation } from '@tanstack/react-query'
import { sendChallenge } from '../../api'
import {
  ChallengeDetails,
  SendChallengeArguments,
} from '../ladder/Ladder.types'
import { Box, Button } from '@mui/material'
import CustomInput from '../common/CustomInput'

interface ChallengeInviteModalProps {
  modalIsOpen: boolean
  closeModal: () => void
  challengeDetails: ChallengeDetails
}

const ChallengeInviteModal = ({
  modalIsOpen,
  closeModal,
  challengeDetails,
}: ChallengeInviteModalProps) => {
  const [challengeSent, setChallengeSent] = useState(false)
  const [message, setMessage] = useState('')

  const closeWrapperFn = () => {
    setChallengeSent(false)
    setMessage('')
    closeModal()
  }

  const mutation = useMutation({
    mutationFn: ({ ladderId, challenge }: SendChallengeArguments) => {
      setChallengeSent(true)
      return sendChallenge({ ladderId, challenge })
    },
  })

  const renderChallengeDetail = () => {
    return challengeSent && mutation.isSuccess ? (
      <div>
        <h3>Challenge sent!</h3>
        <p>
          We will let {challengeDetails.name} know you want to play. Please use
          the chat feature in your profile page to coordinate your match. Once
          agreed upon, please confirm the match on the match invites page and
          then head out to the course!
        </p>
      </div>
    ) : (
      <div>
        <h3>Send Challenge Invite</h3>
        <p>
          Send a message to your opponent with a proposed course and tee time.
          You can use the chat feature in your matches page to continue
          coordinating your match.
        </p>
        <CustomInput
          aria-label="Challenge chat input"
          multiline
          style={{
            marginTop: '16px',
          }}
          placeholder="Send a message to your opponent…"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setMessage(evt.target.value)
          }
          value={message}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'end',
            marginTop: 3,
          }}
        >
          <Button
            variant="text"
            onClick={closeWrapperFn}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!message}
            onClick={() => {
              mutation.mutate({
                ladderId: challengeDetails.ladderId,
                challenge: {
                  challengeeId: challengeDetails.challengeeId,
                  challengeeName: challengeDetails.name,
                  ladderId: challengeDetails.ladderId,
                  messageBody: message || "Let's play a match!",
                  matchProposals: [],
                },
              })
            }}
          >
            Confirm
          </Button>
        </Box>
      </div>
    )
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      contentLabel="Challenge Invite Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={closeWrapperFn}
      style={{
        content: {
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          borderRadius: '8px',
        },
        overlay: {
          overflow: 'hidden',
        },
      }}
    >
      {renderChallengeDetail()}
    </Modal>
  )
}

export default ChallengeInviteModal
