import { Timeline, TimelineItem, TimelineOppositeContent } from '@mui/lab'
import React from 'react'
import ChatMessage from './ChatMessage'
import { Message } from '../ladder/Ladder.types'

interface ChatTimelineProps {
  messages: Message[]
  userId: number
}

function ChatTimeline({ messages, userId }: ChatTimelineProps) {
  if (!messages?.length) return <></>
  return (
    <Timeline position="right" sx={{ background: 'white' }}>
      {messages.map((message: Message, index: number) => {
        const sentByUser = message.author.id === userId
        const msgType = sentByUser ? 'outgoing' : 'incoming'
        const position = sentByUser ? 'right' : 'left'

        return (
          <TimelineItem key={index} position={position}>
            <TimelineOppositeContent color="text.secondary">
              <ChatMessage
                messageText={message.body}
                messageType={msgType}
                messageCreatedAt={message.createdAt}
              />
            </TimelineOppositeContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  )
}

export default ChatTimeline
