import React, { useState } from 'react'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material'
import { sendLogin } from '../../api'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../UserContext'

function LoginForm() {
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [isError, setIsError] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const navigate = useNavigate()
  const { setUserInContext } = useUserContext()

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const formSubmitHandler = () => {
    sendLogin({
      password,
      email,
    })
      .then((res) => {
        setUserInContext(res.data.data)
        navigate('/ladder')
      })
      .catch((_: AxiosError) => {
        setIsError(true)
      })
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': {
          my: '1rem',
        },
        '& .MuiFormControl-root': {
          my: '1rem',
        },
        '& .MuiButton-root': {
          my: '1rem',
        },
      }}
      autoComplete="off"
    >
      <TextField
        fullWidth
        label="Email"
        id="email-input"
        value={email}
        onChange={handleEmailChange}
        InputLabelProps={{ shrink: true }}
      ></TextField>
      <FormControl variant="outlined" fullWidth required>
        <InputLabel shrink={true} htmlFor="password-input">
          Password
        </InputLabel>
        <OutlinedInput
          id="password-input"
          value={password}
          label="Password"
          onChange={handlePasswordChange}
          onKeyDown={(evt: React.KeyboardEvent) => {
            if (evt.code === 'Enter' || evt.key === 'Enter') {
              formSubmitHandler()
            }
          }}
          type={showPassword ? 'text' : 'password'}
          notched={true}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {isError ? (
        <p>Username and/or password incorrect. Please try again.</p>
      ) : null}
      <Button
        fullWidth
        disabled={!password || !email}
        variant="contained"
        onClick={formSubmitHandler}
      >
        Login
      </Button>
    </Box>
  )
}

export default LoginForm
