import React, { useState } from 'react'
import Modal from 'react-modal'
import { useMutation } from '@tanstack/react-query'
import { sendMatchMessage } from '../../api'
import { Box, Button } from '@mui/material'
import CustomInput from '../common/CustomInput'

interface SendTextModalProps {
  ladderId: number
  matchId: number
  modalIsOpen: boolean
  closeModal: () => void
}

interface SendTextMutationFnArgs {
  ladderId: number
  matchId: number
  message: string
}

const SendTextModal = ({
  ladderId,
  matchId,
  modalIsOpen,
  closeModal,
}: SendTextModalProps) => {
  const [textSent, setTextSent] = useState(false)
  const [message, setMessage] = useState('')

  const closeWrapperFn = () => {
    setTextSent(false)
    setMessage('')
    closeModal()
  }

  const mutation = useMutation({
    mutationFn: ({ ladderId, matchId, message }: SendTextMutationFnArgs) => {
      return sendMatchMessage(ladderId, matchId, message, [], true).then(() => {
        setTextSent(true)
        setMessage('')
      })
    },
  })

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={() => {}}
      contentLabel="Challenge Invite Modal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={closeWrapperFn}
      style={{
        content: {
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '400px',
          borderRadius: '8px',
        },
        overlay: {
          overflow: 'hidden',
        },
      }}
    >
      {textSent && mutation.isSuccess ? (
        <div>
          <h3>Message sent!</h3>
          <p>Your playing partner has been notified via text message.</p>
        </div>
      ) : (
        <div>
          <h3>Send message</h3>
          <p>Send a one-time text message to your opponent.</p>
          <CustomInput
            aria-label="Challenge chat input"
            multiline
            style={{
              marginTop: '16px',
            }}
            placeholder="Send a message to your opponent…"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              setMessage(evt.target.value)
            }
            value={message}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'end',
              marginTop: 3,
            }}
          >
            <Button
              variant="text"
              onClick={closeWrapperFn}
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!message}
              onClick={() => {
                mutation.mutate({
                  ladderId,
                  matchId,
                  message,
                })
              }}
            >
              Confirm
            </Button>
          </Box>
        </div>
      )}
    </Modal>
  )
}

export default SendTextModal
